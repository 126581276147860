<template>
  <Layout>
    <PageHeader :title="title" />

    <!-- TO DO: feature to change image after preview -->

    <div class="row d-flex justify-content-center">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-12 my-4" v-if="bimgprev">
                <img :src="bimgprev" style="width: 100%; height: auto;" />

                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="handleImage" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>

              <div class="col-md-3" v-if="otherImg1prev">
                <img :src="otherImg1prev" style="width: 100%; height: 50%;" alt="" />
                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="UploadbannerLogo1" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div class="col-md-3" v-if="otherImg2prev">
                <img :src="otherImg2prev" style="width: 100%; height: 50%;" />
                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="UploadbannerLogo2" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div class="col-md-3" v-if="otherImg3prev">
                <img :src="otherImg3prev" style="width: 100%; height: 50%;" />
                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="UploadbannerLogo3" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
              <div class="col-md-3" v-if="otherImg4prev">
                <img :src="otherImg4prev" style="width: 100%; height: 50%;" />
                <div class="upload-btn-wrapper mt-2">
                  <button class="btn">change image</button>
                  <input @change="UploadbannerLogo4" type="file" name="myfile" id="myfile"
                    accept="image/png, image/gif, image/jpeg" />
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div v-if="!bimgprev" class="mb-3">
                <div class="custom-file">
                  <input type="file" class="custom-file-input" @change="handleImage" />
                  <label class="custom-file-label">{{
                    bannerimageName ? bannerimageName : "Upload banner image"
                    }}</label>
                </div>
              </div>

              <div class="mb-3">
                <div class="row">
                  <div v-if="!otherImg1prev" class="col-md-6">
                    <div class="custom-file">
                      <input @change="UploadbannerLogo1" type="file" class="custom-file-input" />
                      <label class="custom-file-label">{{
                        bannerimage1Name
                        ? bannerimage1Name
                        : "Upload Other images"
                        }}</label>
                    </div>
                  </div>
                  <div v-if="!otherImg2prev" class="col-md-6">
                    <div class="custom-file">
                      <input type="file" @change="UploadbannerLogo2" class="custom-file-input" id="inputGroupFile01"
                        aria-describedby="inputGroupFileAddon01" />
                      <label class="custom-file-label" for="inputGroupFile01">{{
                        bannerimage2Name
                        ? bannerimage2Name
                        : "Upload Other images"
                        }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div class="row">
                  <div v-if="!otherImg3prev" class="col-md-6">
                    <div class="custom-file">
                      <input type="file" @change="UploadbannerLogo3" class="custom-file-input" id="inputGroupFile01"
                        aria-describedby="inputGroupFileAddon01" />
                      <label class="custom-file-label" for="inputGroupFile01">{{
                        bannerimage3Name
                        ? bannerimage3Name
                        : "Upload Other images"
                        }}</label>
                    </div>
                  </div>
                  <div v-if="!otherImg4prev" class="col-md-6">
                    <div class="custom-file">
                      <input type="file" @change="UploadbannerLogo4" class="custom-file-input" id="inputGroupFile01"
                        aria-describedby="inputGroupFileAddon01" />
                      <label class="custom-file-label" for="inputGroupFile01">{{
                        bannerimage4Name
                        ? bannerimage4Name
                        : "Upload Other images"
                        }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label for="subcategory" class="form-label">Super category
                </label>
                <select class="form-control" v-model="superCategoryId" @change="getSubCategories()">
                  <option :value="item.id" v-for="item in superCategories" :key="item.id">
                    {{ item.fullName }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="subcategory" class="form-label">Subcategory </label>
                <select v-model="formData.subCategoryId" class="form-control" aria-label="Default select">
                  <option :value="item.id" v-for="item in subCategories" :key="item.id">
                    {{ item.fullName }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="fullname" class="form-label">Package Full Name</label>
                <input type="text" v-model="formData.fullName" class="form-control" />
                <small class="text-danger" v-if="FNerrMsg">{{
                  FNerrMsg
                  }}</small>
              </div>

              <div class="mb-3">
                <label for="desciption" class="form-label">Description</label>
                <textarea name="description" class="form-control" rows="4" v-model="formData.description"></textarea>
                <small class="text-danger" v-if="LNerrMsg">{{
                  LNerrMsg
                  }}</small>
              </div>

              <div class="form-row mb-3">
                <div class="col">
                  <label for="IsDeliveryByPickup" class="form-label">Delivery By Pickup ?
                  </label>

                  <div class="form-check">
                    <input v-model="formData.IsDeliveryByPickup" class="form-check-input" type="checkbox" />
                    <label class="form-check-label"> Yes </label>
                  </div>
                </div>
                <div class="col">
                  <label for="IsDeliveryByPickup" class="form-label">Delivery by Customer Location?
                  </label>

                  <div class="form-check">
                    <input v-model="formData.IsDeliveryByCustLocation" class="form-check-input" type="checkbox" />
                    <label class="form-check-label"> Yes </label>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label for="effectiveStartDate
" class="form-label">effective Start Date
                </label>
                <datetime format="YYYY-MM-DD H:i:s" width="100%" v-model="formData.effectiveStartDate"></datetime>

                <small class="text-danger" v-if="SDMsg">{{ SDMsg }}</small>
              </div>

              <div class="mb-3">
                <label for="effectiveEndDate
" class="form-label">effective End Date
                </label>
                <datetime format="YYYY-MM-DD H:i:s" width="100%" v-model="formData.effectiveEndDate"></datetime>
                <!-- <input
                  type="date"
                  v-model="formData.effectiveEndDate"
                  class="form-control"
                /> -->
                <small class="text-danger" v-if="ENMsg">{{ ENMsg }}</small>
              </div>

              <div class="mb-3">
                <label for="effectiveEndDate
" class="form-label">Expired Quantyity
                </label>
                <input type="number" v-model="formData.ExpirationQuantity" class="form-control" />
              </div>

              <div class="mb-3">
                <label for="ExpirationQuantityUnit" class="form-label">Expiration Quantity Unit
                </label>
                <select v-model="formData.ExpirationQuantityUnitId" class="form-control" aria-label="Default select">
                  <option :value="item.id" v-for="item in ExpirationQntyUnit" :key="item.id">
                    {{ item.fullName }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="cost
" class="form-label">Cost
                </label>
                <input v-model="formData.Cost" type="text" placeholder="cost" class="form-control" />
                <small class="text-danger" v-if="CostMsg">{{ CostMsg }}</small>
              </div>

              <div class="mb-3">
                <label for="IsDeliveryByPickup" class="form-label">Is Active
                </label>

                <div class="form-check">
                  <input class="form-check-input" v-model="formData.IsActive" type="checkbox" />
                  <label class="form-check-label"> Yes </label>
                </div>
              </div>

              <div class="mb-3">
                <label for="stock
" class="form-label">Total Available Stock
                </label>
                <input type="text" v-model="formData.AvailableStock" placeholder="Total Available Stock"
                  class="form-control" />
              </div>

              <button :disabled="!isDisbaled" @click.prevent="addFoodHub" class="btn btn-block btn-primary">
                <b-spinner v-if="loading" type="grow"></b-spinner>
                <span v-else>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import datetime from "vuejs-datetimepicker";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Food Pack",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, datetime },
  data() {
    return {
      loading: false,
      title: "",
      items: [
        {
          text: "Oonpay",
          href: "/"
        },

        {
          text: "Add Food Pack",
          active: true
        }
      ],
      bimgprev: null,
      otherImg1prev: null,
      otherImg2prev: null,
      otherImg3prev: null,
      otherImg4prev: null,
      url: null,
      bannerimageName: "",
      logo: null,
      subCategoryId: "",
      superCategoryId: "",
      ExpirationQntyUnit: [],
      superCategories: [],
      subCategories: [],
      bannerimage1url: null,
      bannerimage1Name: "",
      bannerimage2url: null,
      bannerimage2Name: "",
      bannerimage3url: null,
      bannerimage3Name: "",
      bannerimage4url: null,
      bannerimage4Name: "",
      formData: {
        fullName: "",
        description: "",
        subCategoryId: "",
        IsDeliveryByPickup: false,
        IsDeliveryByCustLocation: false,
        effectiveStartDate: "",
        effectiveEndDate: "",
        ExpirationQuantity: "",
        ExpirationQuantityUnitId: "",
        Cost: "",
        IsActive: false,
        AvailableStock: ""
      },

      //error msg
      FNerrMsg: "",
      LNerrMsg: "",
      SDMsg: "",
      ENMsg: "",
      CostMsg: ""
    };
  },

  computed: {
    isDisbaled() {
      return (
        this.superCategoryId &&
        this.formData.subCategoryId &&
        this.formData.fullName.length > 0 &&
        this.formData.description.length > 0 &&
        this.formData.effectiveStartDate &&
        this.formData.effectiveEndDate &&
        this.formData.Cost
      );
    }
  },

  mounted() {
    this.$store.dispatch("foodhub/getRequiredData").then((res) => {
      this.superCategories = res.data.item3;
      this.ExpirationQntyUnit = res.data.item4;
    });
  },
  methods: {
    handleImage(e) {
      this.url = e.target.files[0];
      this.bannerimageName = this.url.name;
      this.bimgprev = URL.createObjectURL(this.url);
    },

    UploadbannerLogo1(e) {
      this.bannerimage1url = e.target.files[0];
      this.bannerimage1Name = this.bannerimage1url.name;
      this.otherImg1prev = URL.createObjectURL(this.bannerimage1url);
    },

    UploadbannerLogo2(e) {
      this.bannerimage2url = e.target.files[0];
      this.bannerimage2Name = this.bannerimage2url.name;
      this.otherImg2prev = URL.createObjectURL(this.bannerimage2url);
    },

    UploadbannerLogo3(e) {
      this.bannerimage3url = e.target.files[0];
      this.bannerimage3Name = this.bannerimage3url.name;
      this.otherImg3prev = URL.createObjectURL(this.bannerimage3url);
    },

    UploadbannerLogo4(e) {
      this.bannerimage4url = e.target.files[0];
      this.bannerimage4Name = this.bannerimage4url.name;
      this.otherImg4prev = URL.createObjectURL(this.bannerimage4url);
    },

    getSubCategories() {
      this.$store
        .dispatch("foodhub/getFoodHubSubCat", this.superCategoryId)
        .then((res) => {
          this.subCategories = res.data;
        });
    },

    addFoodHub() {
      if (!this.formData.fullName) {
        return (this.FNerrMsg = "This field cannot be empty");
      }
      if (!this.formData.description) {
        return (this.LNerrMsg = "This field cannot be empty");
      }
      if (!this.formData.effectiveStartDate) {
        return (this.SDMsg = "This field cannot be empty");
      }

      if (!this.formData.effectiveEndDate) {
        return (this.ENMsg = "This field cannot be empty");
      }

      if (!this.formData.Cost) {
        return (this.CostMsg = "This field cannot be empty");
      }

      const payload = new FormData();
      payload.append("fullName", this.formData.fullName);
      payload.append("description", this.formData.description);
      payload.append("subCategoryId", this.formData.subCategoryId);
      payload.append("IsDeliveryByPickup", this.formData.IsDeliveryByPickup);
      payload.append(
        "IsDeliveryByCustLocation",
        this.formData.IsDeliveryByCustLocation
      );
      payload.append("effectiveStartDate", this.formData.effectiveStartDate);
      payload.append("effectiveEndDate", this.formData.effectiveEndDate);
      payload.append("ExpirationQuantity", this.formData.ExpirationQuantity);
      payload.append(
        "ExpirationQuantityUnitId",
        this.formData.ExpirationQuantityUnitId
      );
      payload.append("Cost", this.formData.Cost);
      payload.append("IsActive", this.formData.IsActive);
      payload.append("AvailableStock", this.formData.AvailableStock);
      payload.append("banner", this.url);
      payload.append("bannerLogo1", this.bannerimage1url);
      payload.append("bannerLogo2", this.bannerimage2url);
      payload.append("bannerLogo3", this.bannerimage3url);
      payload.append("bannerLogo4", this.bannerimage4url);

      // for (var pair of payload.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      this.loading = true;
      this.$store
        .dispatch("foodhub/foodhubcreate", payload)
        .then((res) => {
          this.loading = false;
          if (res.status == 200) {
            this.$swal({
              icon: "success",
              title: "success",
              text: "Foodhub pack created successfully"
            });
            this.$router.push("/foodhub-packs");
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    }
  }
};
</script>

<style scoped>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: #f2813e;
  padding: 8px 20px;
  border-radius: 8px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

input[type="file"].custom {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
</style>
